
import store from '@/store';
import { computed, defineComponent, ref } from 'vue'
import './AbadiSearch.scss';

import abadiRouter from '@/composables/datauploadsinfo/abadiRouter';

export default defineComponent({
    setup() {
        const { goToAbadi } = abadiRouter();

        const ai_abadiLimitsDistrict = computed(() => store.getters.ai_abadiLimitsDistrict);

        const filtertaluk = ref('');
        const filtergp = ref('');
        const filtervillage = ref('');
        const filterlgd = ref('');
        const filterabadiname = ref('');

        const filtered_abadilimitslist = computed(() => {
            let featuresCollection = store.getters.ai_abadiLimitsFeatCollection;
            if (featuresCollection.length == 0) return [];

            let mappedAbadiList =  featuresCollection.features.map((feat: any) => {
                return {
                    taluk: feat.properties.taluk,
                    grampanchayat: feat.properties.grampanchayat,
                    villagename: feat.properties.villagename,
                    villagelgdcode: feat.properties.villagelgdcode,
                    abadilimitname: feat.properties.abadilimitname,
                    abadilimituuid: feat.properties.abadilimituuid,
                }
            });

            let talukFilteredAbadiList = mappedAbadiList.filter((abadi: any) => {
                return abadi.taluk.toLowerCase().includes(filtertaluk.value.toLowerCase());
            });

            let gpFilteredAbadiList = talukFilteredAbadiList.filter((abadi: any) => {
                return abadi.grampanchayat.toLowerCase().includes(filtergp.value.toLowerCase());
            });

            let villageFilteredAbadiList = gpFilteredAbadiList.filter((abadi: any) => {
                return abadi.villagename.toLowerCase().includes(filtervillage.value.toLowerCase());
            });

            let lgdFilteredAbadiList = villageFilteredAbadiList.filter((abadi: any) => {
                return abadi.villagelgdcode.toLowerCase().includes(filterlgd.value.toLowerCase());
            });

            let abadinameFilteredAbadiList = lgdFilteredAbadiList.filter((abadi: any) => {
                return abadi.abadilimitname.toLowerCase().includes(filterabadiname.value.toLowerCase());
            });

            return abadinameFilteredAbadiList;
        });

        const callGoToAbadi = (e: any) => {
            let abadilimituuid = e.target.getAttribute('abadilimituuid');
            // console.log(abadilimituuid);
            goToAbadi(abadilimituuid);
        }
        
        return {
            ai_abadiLimitsDistrict, filtertaluk, filtergp, filtervillage, filterlgd, filterabadiname,
            filtered_abadilimitslist, callGoToAbadi
        }
    },
})
